
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-row :gutter="[24, 12]" justify="end">
          <a-col :span="6">
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.orderTimeStart" style="width:100%" valueFormat="YYYY-MM-DD" :format="dateFormat" placeholder="录入时间起" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.orderTimeEnd" style="width:100%" valueFormat="YYYY-MM-DD" :format="dateFormat" placeholder="录入时间止" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.unloadTimeStart" style="width:100%" valueFormat="YYYY-MM-DD" :format="dateFormat" placeholder="卸车时间起" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.unloadTimeEnd" style="width:100%" valueFormat="YYYY-MM-DD" :format="dateFormat" placeholder="卸车时间止" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="" name="driver">
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" name="carNo">
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" style="height: 38px;" placeholder="目的地" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" name="carNo">
              <a-input v-model:value="searchForm.carNo" placeholder="车架号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" name="orderId">
              <a-input v-model:value="searchForm.orderId" placeholder="订单号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-select ref="select" v-model:value="searchForm.officeOrgId" placeholder="归属区域">
                <a-select-option v-for="item in orgs " :key="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-select ref="select" v-model:value="searchForm.isDelivery" placeholder="是否交车">
                <a-select-option v-for="item in whetherAll " :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-input v-model:value="searchForm.salesman" placeholder="业务员" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" style="display:flex;justify-content: space-between;">
              <a-select v-model:value="searchForm.ovStatus" style="width:80%" option-filter-prop="children" show-search :not-found-content="null" allowClear placeholder="运输状态">
                <a-select-option value=''>全部</a-select-option>
                <a-select-option :value=1>已接单</a-select-option>
                <a-select-option :value=2>已推送</a-select-option>
                <a-select-option :value=3>运输中</a-select-option>
                <a-select-option :value=9>完成</a-select-option>
              </a-select>
              <a-button type="primary" style="margin-left:3%" @click="onSearch">
                <template #icon>
                  <SearchOutlined />
                </template>
                查询
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="onClick(record, 7)" :disabled="!deliveryStatus">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        交车
      </a-button>
    </template>
    <a-modal v-model:visible="QRC" title="二维码" ok-text="下载二维码" @ok="downQRC()">
      <div class="QRC">
        <a-spin v-show="QRCloading" tip="Loading..." />
        <div v-show="!QRCloading && QRCcontext === ''" style="text-align:center;margin-bottom:10px">
          车架号：<a-tag color="green">{{ vinNo }}</a-tag>
        </div>
        <QrcodeVue id="QRCpayImg" v-show="!QRCloading && QRCcontext === ''" :value="QRCurl" :size="200" level="H" />
        <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
      </div>
    </a-modal>
    <a-table :columns="[sortColumn, ...columns]" :row-selection="rowSelect" :rowKey="(record, index) => { return index }" :data-source="listData" :pagination="pagination" :loading="loading" :style="{ overflow: 'auto' }" :scroll="{ x:3000, y: 480 }" size="small" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #vinNo="{ record }" >
          <a @click="lookDetail(record)">{{ record.vinNo }}</a>
      </template>
      <template #isDelivery="{ record }">
        <span>{{ record.isDelivery.label }}</span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="orderVehicleGps(record.orderVehicleId)">位置</a>
          <a-divider type="vertical" />
          <a @click="onClick(record, 2)">上传</a>{{ record.picNum }}
          <a-divider type="vertical" />
          <!-- 2024年1月4日14:22:20 LZM移除功能 -->
          <a v-show="record.additionalFeeTotal * 1 > 0" @click="onClick(record, 3)">附加费</a>
          <a-divider v-show="record.additionalFeeTotal * 1 > 0" type="vertical" />
          <a @click="onClick(record, 4)" v-if="record.isDelivery.value === 1">查看</a>
          <a-divider type="vertical" v-if="record.isDelivery.value === 1" />
          <a @click="editDeliveryInfo(record)" v-if="record.isDelivery.value === 1">修改</a>
          <a @click="onClick(record, 4)" v-if="record.isDelivery.value === 0">交车</a>
          <a-divider type="vertical" />
          <a @click="masslossMethod(record)">质损</a>
          <a-divider type="vertical" />
          <a @click="openPayQRC(record)">生成支付码</a>
        </span>
      </template>
      <template #Status="{ record }">
        <span :class="[(record.vehicleTransStatus.value === 0) ? 'f1ab18' : '', (record.vehicleTransStatus.value === 1) ? 'd89f8' : '', (record.vehicleTransStatus.value === 2) ? 'cb32f' : '', (record.vehicleTransStatus.value === 3) ? 'ee5c23' : '', (record.vehicleTransStatus.value === 4) ? 'lv' : '', (record.vehicleTransStatus.value === 5) ? 'e3e3e3' : '']">
          {{ record.vehicleTransStatus.label }}</span>
      </template>
    </a-table>
  </suy-table>
  <carImg ref="carIMG" />
  <addI ref="addi" />
  <!-- <a-modal v-model:visible="visible" title="交车" v-if="visible" @ok="onClick('', 5)" width="40%">
    <a-form-item v-if="form.paidAmt > 0" label="到收款金额">
      <span style="color:red"> {{ form.paidAmt }}</span>
    </a-form-item>
    <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture" :remove="handleRemove"
      :before-upload="beforeUpload">
      <a-button :disabled="imgFileList.length > 0">
        <CameraOutlined />
        上传交车条
      </a-button>
    </a-upload>
    <a-row>
      <a-col :span="24">
        <a-form-item title="收款对象">
          <a-radio-group v-model:value="form.deliveryType">
            <a-radio :value="1">承运商收款</a-radio>
            <a-radio :value="2">办事处收款</a-radio>
            <a-radio :value="3">业务员收款</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item :wrapperCol="{ flex: 'auto' }" v-if="form.deliveryType === 2">
          <a-select v-model:value="form.officeOrgId" allowClear style=" width: 200px;">
            <a-select-option v-for=" item  in  orgs " :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="5">
        <a-form-item>
          <span><span style="color:red">* </span>是否到达办事处：</span>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form :model="form" ref="formRef" :rules="rules">
          <a-form-item name="deliveryOfficeOrg">
            <a-radio-group v-model:value="form.deliveryOfficeOrg">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
    <a-row>
      <span>交车说明：</span>
      <a-textarea v-model:value="form.remark" />
    </a-row>
  </a-modal> -->
  <a-modal v-model:visible="deliveryVisible" title="交车信息查看" width="40%" footer="">
    <a-form-item label="到收款金额" v-if="form.paidAmt > 0">
      <span style="color:red"> {{ form.paidAmt }}</span>
    </a-form-item>
    <span>收车条</span>
    <a-image :width="100" style="height:100px" :src="form.fileUrl" :preview="form.preview" @click="handlePreview(form)" />
    <a-row :gutter="[24, 12]">
      <a-col :span="10">
        <span>收款对象：</span>
        {{ form.deliveryType && form.deliveryType.label }}
      </a-col>
      <a-col :span="7">
        {{ form.officeOrgName }}
      </a-col>
      <a-col :span="7" v-if="form.deliveryOfficeOrg != null">
        {{ form.deliveryOfficeOrg.label }}
      </a-col>
      <a-col :span="24">
        <span>交车人</span>
        {{ form.creatorBy }}
      </a-col>
      <a-col :span="24">
        <span>交车时间</span>
        {{ form.createTime }}
      </a-col>
      <a-col :span="24">
        <span>交车说明：</span>
        {{ form.remark }}
      </a-col>
    </a-row>
  </a-modal>
  
  <a-modal v-model:visible="detailShow" footer="" width="55%" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <template #title>
      <WindowDetail v-if="detail.orderVehicleId" :detailData="detail" />
      <!-- <div>
        <span>{{ detail.vinNo + ' ' + detail.brand + detail.model }}</span>
        <a style="margin-left:10px;font-size:14px;margin-right:10px" @click="showRecord">车辆日志</a>
        <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">车辆图片</a>
        <a style="margin-left:10px;font-size:14px" @click="orderGPS">在途位置</a>
        <a style="margin-left:10px;font-size:14px" @click="abnormalReportShow = true">异常列表</a>
      </div> -->
    </template>
    <div class="left-content" style="width:auto">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detail" :detailType="true" @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
  <!-- 信息日志 -->
  <a-modal v-model:visible="journalShow" width="40%" title="车辆日志" footer="" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <div v-if="detail.orderVehicleId">
      <Record v-if="detail.orderVehicleId" ref="recordRef" :vehicleList="[]" :vehiclesId="detail.orderVehicleId" :orderId="detail.orderId" />
    </div>
  </a-modal>
  <!-- 车辆图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="detail.orderVehicleId" :loadInspectList="[]" />
  <!-- 异常列表 -->
  <!-- <AbnormalReport :vinNo="detail.vinNo" :windowShow="abnormalReportShow" :vehicleDetail="detail" @statusShow="abnormal" /> -->
  <editDelivery v-model:visible="editDeliveryVisible" :orderId="orderId" :orderVehicleId="orderVehicleId" />
  <qualityLoss ref="qualityLoss" />
  <vehicleGps ref="vehicleGps" />
  <!-- 支付二维码 -->
  <PayQRC ref="payQRCref" />
  <!-- 交车 -->
  <GiveVehicle ref="giveVehicleRef" :vehicleId="form.vehicleId" :orderSettlement="orderSettlement" :paidAmt="paidAmt" @successUpload="getSuccessUpload" />
</template>
<script>
import { toRefs, reactive, onMounted, ref, computed } from 'vue'
import { vehicleAll, vehicleDelivery, vehicleDeliveryInfo, vehicleBatchDelivery } from '@/api/transport/truck'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
import carImg from '../car/carImgUplad'
import addI from './additional'
import qualityLoss from './qualityLoss'
import editDelivery from './editDelivery'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'
import vehicleGps from '@/views/order/positionInfo/carGps.vue'
import { QRCpayurl } from '@/api/transport/inspection'
import { getOrgList } from '@/utils/util'
import AbnormalReport from '@/views/components/transport/comon/abnormalReport.vue'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
// import { list as orgList } from '@/api/system/organzition'
import QrcodeVue from 'qrcode.vue'
import { useStore } from 'vuex'
import { uploadVehicle as upload } from '@/api/global'
import dict from '@/config/dict.json'
import PayQRC from '@/views/components/payQRC'
import GiveVehicle from '@/views/components/giveVehicle'

export default {
  components: {
    PayQRC,
    SuyTable,
    WindowDetail,
    carImg,
    addI,
    Record,
    AbnormalReport,
    InspectionImg,
    qualityLoss,
    QrcodeVue,
    editDelivery,
    vehicleGps,
    GiveVehicle,
    TransportModel
  },
  setup () {
    const giveVehicleRef = ref(null)
    const payQRCref = ref(null)
    const recordRef = ref(null)
    const transportModelRef = ref(null)
    const imgListRef = ref(null)
    const vehicleGps = ref(null)
    const store = useStore()
    const state = reactive({
      whetherAll: dict.whetherAll,
      orgs: [],
      QRCurl: '',
      QRCcontext: '',
      vinNo: '',
      detail: {},
      paidAmt: null,
      orderSettlement: null,
      orderId: undefined,
      orderVehicleId: undefined,
      editDeliveryVisible: false,
      abnormalReportShow: false,
      journalShow: false,
      detailShow: false,
      formRef: null,
      vehicleGps: ref(null),
      deliveryStatus: computed(() => (state.datakey.length > 0)),
      imgFileList: [],
      carIMG: ref(null),
      addi: ref(null),
      orgList: [],
      qualityLoss: ref(null),
      QRC: false,
      QRCloading: false,
      loading: false,
      visible: false,
      batchVisible: false,
      deliveryVisible: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        carNo: '',
        orderTimeStart: '',
        orderTimeEnd: '',
        unloadTimeStart: '',
        unloadTimeEnd: '',
        startAddress: {},
        endAddress: {},
        ovStatus: null
      },
      listData: [],
      sortColumn: { title: '序号', width: '40px', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo',
          width:'120px',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress',
          ellipsis: true
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress',
          ellipsis: true
        },
        {
          title: '下单时间',
          width: '5%',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman',
          ellipsis: true
        },
        {
          title: '接单部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label',
          key: 'orderSettlement.label',
          ellipsis: true
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand',
          ellipsis: true
        },

        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '运输状态',
          dataIndex: 'vehicleTransStatus',
          key: 'vehicleTransStatus',
          slots: {
            customRender: 'Status'
          }
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '当前状态',
          dataIndex: 'transStatus.label',
          key: 'transStatus.label'
        },
        {
          title: '中转',
          dataIndex: 'isTransfer.label',
          key: 'isTransfer.label'
        },
        {
          title: '中转地',
          dataIndex: 'transitAddress',
          key: 'transitAddress'
        },
        {
          title: '运车费',
          dataIndex: 'freight',
          key: 'freight'
        },
        {
          title: '取车费',
          dataIndex: 'placeInFee',
          key: 'placeInFee'
        },
        {
          title: '送车费',
          dataIndex: 'takeOutFee',
          key: 'takeOutFee'
        },
        {
          title: '保险费',
          dataIndex: 'insurancePremium',
          key: 'insurancePremium'
        },
        {
          title: '到收金额',
          dataIndex: 'paidAmt',
          key: 'paidAmt'
        },
        {
          title: '合同总金额',
          dataIndex: 'contractAmt',
          key: 'contractAmt'
        },
        {
          title: '合同已结算金额',
          dataIndex: 'settledAmt',
          key: 'settledAmt'
        },
        {
          title: '合同结算状态',
          dataIndex: 'settlementStatus.label',
          key: 'settlementStatus.label'
        },
        {
          title: '运输成本',
          dataIndex: 'freightCost',
          key: 'freightCost'
        },
        {
          title: '税费',
          dataIndex: 'invoiceCost',
          key: 'invoiceCost'
        },
        {
          title: '保险成本',
          dataIndex: 'insuranceCost',
          key: 'insuranceCost'
        },
        {
          title: '毛利',
          dataIndex: 'grossProfit',
          key: 'grossProfit'
        },
        {
          title: '是否交车',
          dataIndex: 'isDelivery',
          key: 'isDelivery',
          slots: {
            customRender: 'isDelivery'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 380,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      form: {
        paidAmt: 0,
        officeOrgId: '',
        vehicleId: '',
        deliveryOfficeOrg: 0
      },
      datakey: [],
      rowSelect: {
        onChange: (datakey, selectedRows) => {
          console.log('selectedRows', selectedRows)
          state.datakey.length = 0
          for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i].isDelivery.value === 0) {
              state.datakey.push(selectedRows[i].orderVehicleId)
            }
          }
        },
        getCheckboxProps: record => ({
          disabled: (record.isDelivery.value === 1 || record.transStatus.value !== 9)
          // disabled: (record.isDelivery.value === 1)
        })
      }
    })
    // 支付码
    const QRCpayImg = record => {
      state.status = 1
      state.vinNo = record.vinNo
      if (state.QRCpay === '') state.QRCloading = true
      QRCpayurl({
        orderId: record.orderId,
        orderVehicleId: [record.orderVehicleId],
        payType: '1',
        ovId: record.orderVehicleId
      }).then(res => {
        if (res.code === 10000) {
          state.QRC = true
          state.QRCloading = true
          state.QRCurl = res.data.qrCode
        }
        if (res.code === 10001) {
          state.QRCcontext = res.msg
        }
      }).catch(err => console.log(err))
        .finally(() => { state.QRCloading = false })
    }
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const getSuccessUpload = e => {
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await vehicleAll({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.listData = records

      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const onClick = (recode, type) => {
      if (recode) {
        state.orderSettlement = recode.orderSettlement.value
        state.paidAmt = recode.paidAmt
      }
      switch (type) {
        case 1:
          if (recode == null) {
            recode = {}
          }
          break
        case 2:
          state.carIMG.oncarid(recode.orderVehicleId)
          state.carIMG.onmodal(true)
          break
        case 3:
          state.addi.oncarid(recode.orderVehicleId)
          state.addi.onSwitch(true)
          break
        case 4:
          state.batchVisible = false
          state.form.vehicleId = recode.orderVehicleId
          if (recode.isDelivery.value === 1) {
            vehicleDeliveryInfo(state.form.vehicleId).then((res) => {
              if (res.code === 10000) {
                if (res.data === null) {
                  state.form.deliveryOfficeOrg = undefined
                  state.visible = true
                  state.driverLoading = false
                  state.form.deliveryType = {}
                  state.form.remark = undefined
                  state.imgFileList = []
                } else {
                  state.form = res.data
                  state.deliveryVisible = true
                }
                if (recode.orderSettlement.value === 1 | recode.orderSettlement.value === 3) {
                  state.form.paidAmt = recode.paidAmt
                } else {
                  state.form.paidAmt = 0
                }
              }
            })
          } else {
            setTimeout(() => {
              if (giveVehicleRef.value) giveVehicleRef.value.windowShow = true, giveVehicleRef.value.getDataProps()
            }, 10)
          }
          break
        case 5:
          if (state.form.deliveryOfficeOrg === undefined || state.form.deliveryOfficeOrg < 0 || state.form.deliveryOfficeOrg > 2) {
            message.success('没选择是否到办事处')
            return false
          }
          state.formRef
            .validate()
            .then(() => {
              if (state.batchVisible === true) {
                batchDelivery()
              } else {
                onClick(recode, 6)
              }
            }).catch(error => {
              console.log(error)
            })
          break
        case 6:
          if (state.imgFileList.length > 0) {
            const formData = new FormData()
            state.imgFileList.forEach(file => {
              formData.append('file', file.originFileObj)
            })
            upload(formData).then(res => {
              if (res.code === 10000) {
                state.form.fileId = res.data.id
                vehicleDelivery(state.form.vehicleId, state.form
                ).then((res) => {
                  if (res.code === 10000) {
                    message.success(res.msg)
                    state.form = {
                      paidAmt: 0,
                      officeOrgId: '',
                      vehicleId: '',
                      deliveryOfficeOrg: undefined
                    }
                    loadData()
                  }
                  state.driverLoading = false
                })
              }
            })
            state.visible = false
          } else {
            message.error('收车条必须传')
          }
          break
        case 7:
          state.visible = true
          if (giveVehicleRef.value) {
            giveVehicleRef.value.batchVisible = true
            giveVehicleRef.value.datakey = state.datakey
            giveVehicleRef.value.windowShow = true
            giveVehicleRef.value.getDataProps()
          }
          break
        case 8:
          state.batchVisible = false
          state.form.vehicleId = recode.orderVehicleId
          vehicleDeliveryInfo(state.form.vehicleId).then((res) => {
            if (res.code === 10000) {
              if (res.data === null) {
                state.form.deliveryOfficeOrg = undefined
                state.visible = true
                state.driverLoading = false
                state.form.deliveryType = {}
                state.form.remark = undefined
                state.imgFileList = []
              } else {
                state.form = res.data
                state.deliveryVisible = true
              }
              if (recode.orderSettlement.value === 1 | recode.orderSettlement.value === 3) {
                state.form.paidAmt = recode.paidAmt
              } else {
                state.form.paidAmt = 0
              }
            }
          })
          break
        default:
          message.success('出现错误' + type)
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const batchDelivery = () => {
      if (state.imgFileList.length > 0) {
        const formData = new FormData()
        state.imgFileList.forEach(file => {
          formData.append('file', file.originFileObj)
        })
        upload(formData).then(res => {
          if (res.code === 10000) {
            state.form.fileId = res.data.id
            state.form.orderVehicleId = ''
            state.form.orderVehicleIds = state.datakey
            vehicleBatchDelivery(state.form
            ).then((res) => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.form = {
                  paidAmt: 0,
                  officeOrgId: '',
                  vehicleId: '',
                  deliveryOfficeOrg: undefined
                }
                loadData()
              }
              state.driverLoading = false
            })
          }
        })
        state.visible = false
      } else {
        message.error('收车条必须传')
      }
    }
    const oNorgList = async () => {
      state.orgs = store.state.app.orgTypeList
      if (state.orgs.length <= 0 || state.orgs === null || state.orgs === undefined || state.orgs.length <= 0) {
        state.orgs = await getOrgList(1)
        // orgList({ orgType: 1 }).then(res => {
        //   state.orgs = res.data
        // })
      }
    }
    onMounted(oNorgList)

    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }

    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }
    // getOrgList({ orgType: 1 }).then(res => { state.orgList = res.data })
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const masslossMethod = (recode) => {
      console.log('state.orgs', state.orgs)
      state.qualityLoss.oncarid(recode.orderVehicleId, state.orgs)
      state.qualityLoss.onSwitch(true)
    }
    const editDeliveryInfo = (recode) => {
      state.orderVehicleId = recode.orderVehicleId
      state.orderId = recode.orderId
      state.editDeliveryVisible = true
    }
    const orderVehicleGps = (id) => {
      setTimeout(() => {
        vehicleGps.value.onvisible(true)
        vehicleGps.value.onorderVehicleId(id)
      }, 10)
    }
    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    const lookDetail = record => {
      state.detail = record
      state.detailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const showRecord = () => {
      state.journalShow = true
      setTimeout(() => {
        recordRef.value.loadData()
      }, 10)
    }
    // 车辆图片
    const getUpdateImg = () => {
      setTimeout(() => {
        imgListRef.value.inspectionImg = true
        imgListRef.value.loadData(state.detail.orderVehicleId)
      }, 100)
    }
    // 在途位置gps
    const orderGPS = () => {
      setTimeout(() => {
        vehicleGps.value.onvisible(true)
        vehicleGps.value.onorderVehicleId(state.detail.orderVehicleId)
      }, 50)
    }
    // 异常列表回传
    const abnormal = e => {
      state.abnormalReportShow = false
    }
    const openPayQRC = (record) => {
      let obj = {
        startAddress: record.startAddress,
        endAddress: record.endAddress,
        orderId: record.orderId,
        ovId: record.orderVehicleId,
        vehicleList: [{ vinNo: record.vinNo }]
      }
      setTimeout(() => {
        payQRCref.value.openWindow(obj)
      }, 10)
    }
    return {
      ...toRefs(state),
      payQRCref,
      recordRef,
      transportModelRef,
      giveVehicleRef,
      imgListRef,
      openPayQRC,
      vehicleGps,
      getSuccessUpload,
      onSearch,
      QRCpayImg,
      loadData,
      showRecord,
      orderGPS,
      abnormal,
      lookDetail,
      getUpdateImg,
      beforeUpload,
      handleRemove,
      batchDelivery,
      oNorgList,
      setEndAddress,
      setStartAddress,
      handleTableChange,
      masslossMethod,
      editDeliveryInfo,
      onClick,
      orderVehicleGps,
      handlePreview
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
@import '../../components/transport/index.less';

.QRC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.f1ab18 {
  color: red;
}

.d89f8 {
  color: #5d89f8;
}

.cb32f {
  color: #9cb32f;
}

.lv {
  color: #34b969;
}

.ee5c23 {
  color: #ee5c23;
}

.e3e3e3 {
  color: #e3e3e3;
}

.f47920 {
  color: red;
}

.e {
  color: #40835e;
}

.cd7 {
  color: #317cd7;
}
</style>
